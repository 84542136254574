import { CircularProgress, Grid, Stack } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  useExportPerformanceReportMutation,
  useGetPerformanceReportMutation,
} from "../../../request/Report";
import Translate from "../../../translation/utils/translateFunction";
import CompanyListing from "../../ContactRepository/component";
import IconifyIcon from "../../Icon";
import ListView from "../../ListView";
import DropDownField from "../../TextField/DropdownField";
import { getPerformanceReportColumns } from "./Components/column.data";
import { sortingOptions } from "./Components/constant";
import { formatDateYYYYMMDD } from "../../../common/Utils/DateFormater";
import { CSVLink } from "react-csv";
import {
  userTypeOptions,
  companyTypeOptions,
  companyType,
} from "../components/constants";
import "./performance-reports.css";
import CompanyHeader from "../../../common/CompanyHeader";
import Searchbar from "../../common/Searchbar";
import { getMoment } from "../../../common/Utils/commonFunction";

const PerformanceReport = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const [isLoading, setIsLoading] = useState(false);
  const [selectedSortBy, setSelectedSortBy] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [reportData, setReportData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRole, setSelectedRole] = useState([]);
  const [selectedType, setSelectedType] = useState("ALL");
  const [staffOptions, setStaffOptions] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const csvLink = useRef();
  const [allFilter, setAllFilters] = useState([]);

  useEffect(() => {
    if (userData) {
      const { performance } = userData;

      const staffValues = performance.map((role) => ({
        label: role in userTypeOptions && userTypeOptions[role],
        value: role,
      }));
      staffValues.splice(0, 0, {
        label: Translate("filter:all"),
        value: "ALL",
      });
      setStaffOptions(staffValues);
      setSelectedRole([staffValues[0].value]);
      setAllFilters(performance?.slice(1));
    }
  }, [userData]);

  const clearFilter = () => {
    setCurrentPage(1);
    setSelectedType("ALL");
    setSelectedSortBy(1);
    setSelectedRole([staffOptions[0].value]);
    setAllFilters(userData?.performance.slice(1));
  };

  const showFilters = () => {
    setShowFilter(!showFilter);
  };

  const [
    performanceReport,
    {
      data: performanceReportData,
      isLoading: isLoadingReport,
      isError: isErrorReport,
      error: errorReport,
    },
  ] = useGetPerformanceReportMutation();

  const requestObj = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const finalReq = {
      companyId: companies,
      searchText: searchText,
      sortingOrder: selectedSortBy,
      role: selectedRole && selectedRole[0] === "ALL" ? [] : selectedRole,
      type: selectedType === "ALL" ? companyType : [selectedType],
      pageNo: currentPage,
    };

    return companies?.length ? finalReq : null;
  }, [
    currentPage,
    searchText,
    selectedSortBy,
    selectedCompanyList,
    selectedType,
    selectedRole,
    allFilter,
  ]);

  useEffect(() => {
    requestObj && performanceReport(requestObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);

  const rowsPerPage = 10;
  const startSerialNumber = (currentPage - 1) * rowsPerPage + 1;

  const rowsWithSerialNumbers = reportData?.map((row, index) => ({
    ...row,
    serialNumber: startSerialNumber + index,
  }));
  const [
    exportPerformanceReportApi,
    {
      data: exportPerformanceReport,
      isLoading: isExportLoading,
      error: exportError,
    },
  ] = useExportPerformanceReportMutation();

  //   api response render and show error
  useEffect(() => {
    if (performanceReportData?.data?.nodes) {
      const { nodes, pageInfo } = performanceReportData.data;
      setReportData(nodes);
      setLastPage(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if (errorReport && isErrorReport) {
      errorReport?.originalStatus !== 500 &&
        showToast({
          message:
            JSON.stringify(errorReport.data) ||
            Translate("common:unknownServerError"),
          type: "error",
        });
      setIsLoading(false);
    }
  }, [performanceReportData, errorReport, isErrorReport]);

  const customActionCellRender = (params) => {
    return (
      <td>
        <i
          className="manageIconColor m-2 fa fa-eye cursor-pointer"
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}
          onClick={() =>
            navigate("/performanceDetails", {
              state: { _id: params?.row?._id },
            })
          }
        ></i>
      </td>
    );
  };

  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const setFilterValue = (item, placeholder) => {
    setCurrentPage(1);
    // eslint-disable-next-line default-case
    switch (placeholder) {
      case Translate("manageTask:titlePlaceHolder"):
        // eslint-disable-next-line no-lone-blocks
        {
          item.target.value !== placeholder
            ? setSelectedType(item.target.value)
            : setSelectedType("");
        }
        break;

      case Translate("performanceReport:sortByPlaceHolder"):
        // eslint-disable-next-line no-lone-blocks
        {
          item.target.value !== placeholder
            ? setSelectedSortBy(Number(item.target.value))
            : setSelectedSortBy(1);
        }
        break;

      case Translate("performanceReport:staffPlaceHolder"):
        // eslint-disable-next-line no-lone-blocks
        {
          item.target.value !== placeholder
            ? setSelectedRole([item.target.value])
            : setSelectedRole([]);
        }
        if (item.target.value !== "ALL") {
          {
            item.target.value !== placeholder
              ? setAllFilters([item.target.value])
              : setAllFilters([]);
          }
        } else {
          {
            item.target.value !== placeholder
              ? setAllFilters(userData?.performance.slice(1))
              : setAllFilters([]);
          }
        }
        break;
    }
  };

  useEffect(() => {
    if (exportPerformanceReport?.length > 0) {
      const rows = exportPerformanceReport?.split("\n");
      const rowArray = rows.map((item) => item.trim().split(","));

      setCSVData(rowArray);
    }

    if (exportError) {
      const errorResponse = JSON.parse(exportError?.data);

      exportError?.originalStatus !== 500 &&
        showToast({
          message:
            JSON.stringify(errorResponse?.message) ||
            Translate("common:unknownServerError"),
          type: "error",
        });
    }
  }, [exportPerformanceReport, exportError]);

  useEffect(() => {
    if (csvData?.length > 0) {
      // eslint-disable-next-line no-unused-expressions
      csvLink?.current?.link?.click();
    }
  }, [csvData]);

  const requestObjectCSV = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const finalReq = {
      companyId: companies,
      searchText: searchText,
      sortingOrder: selectedSortBy,
      role: selectedRole && selectedRole[0] === "ALL" ? [] : selectedRole,
      type: selectedType === "ALL" ? companyType : [selectedType],
      pageNo: currentPage,
    };

    return companies?.length ? finalReq : null;
  }, [
    currentPage,
    searchText,
    selectedSortBy,
    selectedCompanyList,
    selectedType,
    selectedRole,
    allFilter,
  ]);

  const handleExportTask = () => {
    exportPerformanceReportApi(requestObjectCSV);
  };

  const handleSearchText = (value) => {
    selectedCompanyList?.length > 0 && setCurrentPage(1);
    setSearchText(value);
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="card cardHeight">
              <div className="row">
                <div className="col-12">
                  <div className="card-header fixedHeight">
                    <CompanyHeader setShowModal={setShowModal} />
                    <div className="card-options">
                      <div className="sort_stat commentTextField">
                        {showFilter ? (
                          <div className="row">
                            <div
                              className="col-lg-4 col-md-4 col-sm-6"
                              style={{ marginTop: "9px" }}
                            >
                              <StyledTypography
                                sx={{ color: colors.primary_009 }}
                                component="span"
                              >
                                {Translate("manageTask:title")}
                              </StyledTypography>
                              <div className="multiselect_div selectWidth">
                                <div className="form-group cursor-pointer">
                                  <DropDownField
                                    options={companyTypeOptions}
                                    className={
                                      "form-control textFieldHeight cursor-pointer"
                                    }
                                    placeholder={Translate(
                                      "manageTask:titlePlaceHolder"
                                    )}
                                    onChange={(item) =>
                                      setFilterValue(
                                        item,
                                        Translate("manageTask:titlePlaceHolder")
                                      )
                                    }
                                    value={selectedType}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-lg-4 col-md-4 col-sm-6"
                              style={{ marginTop: "9px" }}
                            >
                              <StyledTypography
                                sx={{ color: colors.primary_009 }}
                                component="span"
                              >
                                {Translate("performanceReport:sortByLabel")}
                              </StyledTypography>
                              <div className="multiselect_div selectWidth">
                                <div className="form-group cursor-pointer">
                                  <DropDownField
                                    options={sortingOptions}
                                    className={
                                      "form-control textFieldHeight cursor-pointer"
                                    }
                                    placeholder={Translate(
                                      "performanceReport:sortByPlaceHolder"
                                    )}
                                    onChange={(item) =>
                                      setFilterValue(
                                        item,
                                        Translate(
                                          "performanceReport:sortByPlaceHolder"
                                        )
                                      )
                                    }
                                    value={selectedSortBy}
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-lg-4 col-md-4 col-sm-6"
                              style={{ marginTop: "9px" }}
                            >
                              <StyledTypography
                                sx={{ color: colors.primary_009 }}
                                component="span"
                              >
                                {Translate("performanceReport:staffLabel")}
                              </StyledTypography>
                              <div className="multiselect_div selectWidth">
                                <div className="form-group cursor-pointer">
                                  <DropDownField
                                    options={staffOptions}
                                    className={
                                      "form-control textFieldHeight cursor-pointer"
                                    }
                                    placeholder={Translate(
                                      "performanceReport:staffPlaceHolder"
                                    )}
                                    onChange={(item) =>
                                      setFilterValue(
                                        item,
                                        Translate(
                                          "performanceReport:staffPlaceHolder"
                                        )
                                      )
                                    }
                                    value={selectedRole}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div
                          className="filterIconBox headerMargin mx-2"
                          onClick={() => {
                            showFilter && clearFilter();
                            showFilters(!showFilter);
                          }}
                        >
                          {showFilter ? (
                            <span
                              data-toggle="tooltip"
                              title={Translate("common:clearFilterToolTip")}
                            >
                              <IconifyIcon
                                icon="mdi:filter-off"
                                width="19"
                                className="dateRangeFilterIcon"
                              />
                            </span>
                          ) : (
                            <span
                              data-toggle="tooltip"
                              title={Translate("common:showFilterToolTip")}
                            >
                              <IconifyIcon
                                icon="fa:filter"
                                width="14"
                                className="dateRangeFilterIcon"
                              />
                            </span>
                          )}
                        </div>
                        <form
                          className="headerMargin"
                          style={{ display: "flex" }}
                        >
                          <div className="input-group mr-2">
                            <Searchbar
                              handleSearchText={handleSearchText}
                              setSearch={setSearchText}
                            />
                          </div>

                          <div className="header-action pull-right">
                            <button
                              type="button"
                              className="btn btn-primary saveButton py-2"
                              onClick={handleExportTask}
                              disabled={!rowsWithSerialNumbers?.length}
                            >
                              {isExportLoading ? (
                                <div className="align-self-center">
                                  <CircularProgress size={22} color="inherit" />
                                </div>
                              ) : (
                                <>
                                  <i className="fe fe-download" />
                                  <StyledTypography
                                    className="mx-1"
                                    component="span"
                                  >
                                    {Translate("common:exportLabel")}
                                  </StyledTypography>
                                </>
                              )}
                            </button>

                            {csvData?.length > 0 && (
                              <CSVLink
                                data={csvData}
                                ref={csvLink}
                                filename={`${Translate(
                                  "taskReport:exportPerformanceFileName"
                                )}_${formatDateYYYYMMDD(
                                  getMoment().toDate()
                                )}.csv`}
                              />
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ListView
                  columns={getPerformanceReportColumns(
                    customActionCellRender,
                    customSrCellRender
                  )}
                  rows={rowsWithSerialNumbers}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLoading || isLoadingReport}
                  showLoader={isLoading || isLoadingReport}
                  totalPageCount={lastPage}
                />
              </div>
            </div>
          </div>
        </div>
        <CompanyListing
          handleModal={(value) => {
            setCurrentPage(1);
            setShowModal(value);
          }}
          showModal={showModal}
          selectedCompanyList={selectedCompanyList}
        />
      </StyledCard>
    </Grid>
  );
};

export default PerformanceReport;
