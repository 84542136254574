export default {
  ok: "اوك",
  cancel: "الغاء",
  save: "حفظ",
  addMore: "أضف المزيد",
  invite: "يدعو",
  addButton: "يضيف",
  share: "يشارك",
  yes: "نعم",
  apply: "يتقدم",
  send: "يرسل",
  saveChanges: "حفظ التغييرات",
  done: "منتهي",
  confirm: "يتأكد",
  add: "يضيف",
  errorMessage: "لم يتم العثور على تعليقات",
  edit: "يحرر",
  delete: "يمسح",
  search: "يبحث",
  searchTask: "مهمة البحث",
  selectAll: "اختر الكل",
  takePhoto: "التقاط صورة",
  uploadGallery: "تحميل من المعرض",
  copy_Paste: "نسخ/لصق النص",
  accessLogs: "سجلات الوصول",
  performance: "أداء",
  rate: "معدل",
  redZone: "المنطقة الحمراء",
  greenZone: "منطقة خضراء",
  calendar: "التقويم",
  inbox: "صندوق الرسائل",
  home: "بيت",
  message: "رسالة",
  manageTask: "إدارة المهمة",
  alert: "يُحذًِر",
  imagePreviewAlert: "مسموح بملف واحد فقط.",
  notificationAlert:
    "لتلقي التحديثات في الوقت الحقيقي من مستخدمين آخرين، يرجى تمكين إذن الإعلام. يرجى الرجوع إلى الرابط لتمكين الإخطار -",
  referThis: "التعليمات هنا...",
  allCompanySelect: "تم اختيار جميع الشركات",
  selectCompany: "اختر الشركة",
  unAuthTitle: "دخول غير مرخص",
  unAuthsubtitle: "أنت غير مفوض لدخول هذه الصفحة.",
  unAuthBtn: "خذنى إلى المنزل",
  editToolTip: "يحرر",
  backToolTip: "عُد",
  viewToolTip: "منظر",
  deleteToolTip: "يمسح",
  searchPlaceholder: "ابحث عن شيء...",
  close: "يغلق",
  fileAddLimitMsg: "يمكنك إرسال مرفق واحد فقط في كل مرة.",
  dropZoneFileSizeMsg: "لا يمكنك إضافة حجم ملف يزيد عن 50 ميجابايت.",
  fileAddLimitMsg10: " الحد الأقصى المسموح به هو.",
  maximum: "ملفات",
  invalidFileMsg: "تنسيق ملف غير صالح",
  unknownServerError: "هناك خطأ ما!",
  continue: "يكمل",
  showFilterToolTip: "إظهار الفلتر",
  clearFilterToolTip: "مرشح واضح",
  selectCompanyMessage: "الرجاء اختيار شركة واحدة على الأقل",
  dateRangePlaceholder: "من تاريخ - حتى الآن",
  removeToolTip: "يزيل",
  forManagemnet: "للإدارة",
  srNoTitle: "الأب رقم",
  actionTitle: "فعل",
  nextToolTip: "التالي",
  shareToolTip: "يشارك",
  loadMoreBtnLabel: "تحميل المزيد",
  apiFailedMessage: "فشل في جلب البيانات",
  sortByTitle: "ترتيب حسب",
  clearAllTitle: "امسح الكل",
  required: "يتطلب حقلا",
  noDataFoundMessage: "لاتوجد بيانات",
  welcome: "مرحباً",
  english: "إنجليزي",
  language: "لغة",
  deleteProfileAlert: "هل أنت متأكد أنك تريد حذف صورة الملف الشخصي هذه؟",
  account: "حساب",
  logOut: "تسجيل خروج",
  personalAssistant: "مساعد شخصي",
  generalManager: "مدير عام",
  exportLabel: "يصدّر",
  datePlaceHolder: "حدد تاريخ",
  copy: "ينسخ",
  copyMsg: "نسخ",
  imageSizeLimitMsg: "لا يمكنك إضافة حجم صورة أكبر من 10 ميجابايت.",
  click: "انقر",
  allow: "يسمح",
  filterTitle: "المرشحات",
  today: "اليوم",
  yesterday: "أمس",
  self: "الذات",
  ScreenViewalertMsg: "عذرًا، هذا الموقع متاح فقط على أجهزة سطح المكتب.",
  noteTitle: "ملحوظة",
  duplicateFileError: "تم تحميلها بالفعل",
  arabic: "عربي",
  loadingTitle: "تحميل...",
  day: "دد",
  month: "مم",
  year: "يييي",
  hour: "حح",
  minute: "مم",
  holidayPresent: "العطلة موجودة بالفعل!",
  weekendsError: "حدد يومين على الأقل لتعيين عطلات نهاية الأسبوع",
  allCompanySelectworkload: "جميع الشركات",
  addmom: "اضف ميام",
  xlsB64PreviewError:
    "تأكيد غير متاح لهذا النوع من الملفات قبل الرفع. يرجى المتابعة مع عملية الرفع لعرض المحتويات.",
  noEventMsg: "لم يتم العثور على أي أحداث",
  download: "تحميل",
  downloadAlert: "هل أنت متأكد أنك تريد تنزيل",
  pdfDownloadError: "خطأ في جلب ملف PDF",
  loadingText: "جارٍ التحميل...",
  dropZoneFileSizeMsg10MB: "لا يمكنك إضافة حجم ملف يزيد عن 10 ميجابايت.",
  hrmanager: "مدير الموارد البشرية",
};
