import React, {
  useRef,
  useState,
  memo,
  useLayoutEffect,
  useEffect,
} from "react";
import { StyledTypography } from "../../common/Utils/StyledComponents";

const TextWrapWithTooltip = ({
  label,
  customClassName = "maxwidth-350",
  customStyle = {},
  textVariant = "h6",
  role,
  hasPinned = false,
  hasFlagged = false,
}) => {
  const textElementRef = useRef(null);
  const [isOverflown, setIsOverflown] = useState(false);

  const overflowCheck = () => {
    const element = textElementRef.current;

    if (element) {
      const compare =
        element.offsetWidth < element.scrollWidth ||
        element.offsetHeight < element.scrollHeight;
      setIsOverflown(compare);
    }
  };

  useEffect(() => {
    // Initial check to set initial overflow state after label update
    overflowCheck();
  }, [label]);

  useLayoutEffect(() => {
    // Initial check to set initial overflow state
    overflowCheck();

    setTimeout(() => overflowCheck(), 100);

    // Add event listener for window resize
    window.addEventListener("resize", overflowCheck);

    return () => {
      // Remove event listener on unmount
      window.removeEventListener("resize", overflowCheck);
    };
  }, []);

  return (
    <>
      {isOverflown ? (
        <StyledTypography
          variant={textVariant}
          className="commentSectionIcon pr-0"
        >
          <StyledTypography
            ref={textElementRef}
            variant={textVariant}
            className={`ellipsisStyle ${customClassName}`}
            sx={{ ...customStyle }}
            data-toggle="tooltip"
            title={label}
          >
            {`${label} `}
          </StyledTypography>
          {hasFlagged && <span className={`iconFlagColorStyle fa fa-flag-o`} />}
          {hasPinned && (
            <span className={`iconPinColorStyle fa fa-thumb-tack`} />
          )}
          {role && (
            <StyledTypography
              ref={textElementRef}
              variant={textVariant}
              sx={{ ...customStyle }}
              data-toggle="tooltip"
              title={role}
            >
              | {role}
            </StyledTypography>
          )}
        </StyledTypography>
      ) : (
        <StyledTypography
          variant={textVariant}
          className="commentSectionIcon  pr-0 w-100"
        >
          <StyledTypography
            ref={textElementRef}
            variant={textVariant}
            className={`ellipsisStyle ${customClassName}`}
            sx={{
              ...customStyle,
            }}
            title={role ? label : ""}
          >
            {`${label} `}
          </StyledTypography>
          {hasFlagged && (
            <span className={`iconFlagColorStyle fa fa-flag-o mx-1`} />
          )}
          {hasPinned && (
            <span className={`iconPinColorStyle fa fa-thumb-tack mx-1`} />
          )}
          {role && (
            <StyledTypography
              ref={textElementRef}
              variant={textVariant}
              sx={{ marginLeft: "5px", ...customStyle }}
            >
              | {role}
            </StyledTypography>
          )}
        </StyledTypography>
      )}
    </>
  );
};

export default memo(TextWrapWithTooltip);
