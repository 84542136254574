export default {
  dashboard: "لوحة التحكم",
  contactRepository: "بيانات جهات الاتصال",
  documentRepository: "بيانات الملفات",
  report: "تقرير",
  taskReport: "تقرير المهمة",
  performanceReport: "تقرير الأداء",
  workloadReport: "تقرير حجم العمل",
  staffReport: "تقرير طاقم العمل",
  vendorsSuppliers: "الموردين",
  aboutUs: "عنا",
  privacy: "سياسة الخصوصية",
  termsConditions: "البنود و الظروف",
  logout: "خروج",
  staff: "طاقم العمل",
  owner: "المالك",
  manager: "المدير",
  employee: "الموظف",
  vendor: "البائع",
  attendanceReport: "تقرير الحضور",
  voiceNote: "تسجيل صوتي",
  activityLogs: "سجل النشاطات",
  renewals: "التجديدات",
  organizationStructure: "الهيكل التنظيمي",
  companyStructure: "هيكل الشركة",
  reportingStructure: "هيكل التقارير",
  company: "شركة",
  notifications: "إشعارات",
  settings: "إعدادات",
  myVoiceNotes: "رسائلي الصوتيه",
  sharedWithMeVoiceNotes: "التى تم مشاركتها معي",
  changePass: "تغيير كلمة السر",
  changeTimeZone: "تغيير  المنطقة الزمنية",
  personalAssistant: "المساعد/ة الشخصي",
  personalAssistantSidebar: "صأ",
  staffPerformance: "أداء العاملين",
  generalManager: "المدير العام",
  generalManagerSidebar: "المدير العام",
  privateTitle: "قائمة خاصة",
  publicTitle: "القائمة العامة",
  shareTitle: "القائمة المشتركة",
  momTitle: "ميام",
  momMyEvent: "أحداثي",
  editMom: "يحرر ميام",
  hrOfficer: "ضابط الموارد البشرية",
  project: "مشروع",
  projectReport: "تقرير المشروع",
  manageTask: "إدارة المهمة",
  myDocuments: "ملفاتي",
  sharedWithMe: "التى تم مشاركتها معي",
  customReport: "تقرير مخصص",
  companyReport: "تقرير الشركة",
  applyLeave: "التقدم بطلب للحصول على إجازة",
  manageRequest: "إدارة الطلب",
  leaveReq: "طلب ترك",
  officer: "ضابط",
  hrofficer: "حرضابط",
  Privacy: "خصوصية",
  myLeaveReq: "طلب إجازتي",
};
