export default {
  name: "User name",
  role: "Designation",
  status: "Status",
  leaveDur: "Leave duration",
  from: "From date",
  to: "To date",
  date: "Date",
  requestedDate: "Requested on",
  selectallRequest: "Select all request",
  myLeave: "My leave requests",
  leaveReason: "Leave reason",
  disapproveReason: "Disapproved reason",
  reasonTitle: "Add reason",
  reasonplaceholder: "Add",
  disapproveTaskAlert: "Are you sure you want to disapprove this request?",
  approveTaskAlert: "Are you sure you want to approve this request?",
  addReason: "Add reason",
};
